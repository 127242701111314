/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import ResponsiveAutoHeight from 'responsive-auto-height';
import focusLock from 'dom-focus-lock';
import AOS from 'aos';

if (document.querySelectorAll('.same-height').length) {
	new ResponsiveAutoHeight('.same-height');
}

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-out'
});

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight + 3}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Headroom
import Headroom from "headroom.js";
var myElement = document.querySelector("header");
var headroom = new Headroom(myElement);
headroom.init();

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Close dropdown in textblock on backdrop click or escape key
document.querySelectorAll('.text-dropdown-checkbox').forEach(function (dropdown) {
	if (dropdown) {
		document.addEventListener('click', e => {
			if (!dropdown.contains(e.target) && (dropdown.checked) && (e.target.classList.contains("text-dropdown-label") == false)) {
				toggle(false);
			}
		});
		const toggle = checked => {
			dropdown.checked = checked;

			if (checked) {
				focusLock.on(dropdown);
			} else {
				focusLock.off(dropdown);
			}
		};

		document.addEventListener('keydown', e => {
			if (e.keyCode === 27 && dropdown.checked) {
				toggle(false);
			}
		});

		dropdown.addEventListener('change', () => {
			toggle(dropdown.checked);
		});
	}
});

// Textblock dropdowns
document.querySelectorAll('.dropdown-checkbox').forEach(function (dropdown) {
	if (dropdown) {
		document.addEventListener('click', e => {
			if (!dropdown.contains(e.target) && (dropdown.checked) && (e.target.classList.contains("dropdown-label") == false)) {
				toggle(false);
			}
		});
		const toggle = checked => {
			dropdown.checked = checked;

			if (checked) {
				focusLock.on(dropdown);
			} else {
				focusLock.off(dropdown);
			}
		};

		document.addEventListener('keydown', e => {
			if (e.keyCode === 27 && dropdown.checked) {
				toggle(false);
			}
		});

		dropdown.addEventListener('change', () => {
			toggle(dropdown.checked);
		});
	}
});

// Main navigation
document.querySelectorAll('.topnavdropdown-checkbox').forEach(function (dropdown) {
	if (dropdown) {

		document.addEventListener('click', e => {
			//console.log(e.target);
			if (!dropdown.contains(e.target) && (dropdown.checked) && (e.target.classList.contains("topnavdropdown-label") == false)) {
				toggle(false);
			}
		});

		const toggle = checked => {
			dropdown.checked = checked;

			if (checked) {
				focusLock.on(dropdown);
			} else {
				focusLock.off(dropdown);
			}
		};

		document.addEventListener('keydown', e => {
			if (e.keyCode === 27 && dropdown.checked) {
				toggle(false);
			}
		});

		dropdown.addEventListener('change', () => {
			toggle(dropdown.checked);
		});
	}
});

// Image slider 
var imageSlider = document.querySelectorAll('.image-block .swiper-container');
if (imageSlider.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var imgSlider = new Swiper(".image-block .swiper-container", {
			slidesPerView: '1',
			breakpoints: {
				// when window width is >= 1024px
				1024: {
					slidesPerView: 2
				}
			},
			navigation: {
				nextEl: ".img-btn-next",
				prevEl: ".img-btn-prev",
			}
		});
	})();
}

// Footer slider 
var sliderBlock = document.querySelectorAll('.slider-block .swiper-container');
if (sliderBlock.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var slider = new Swiper(".slider-block .swiper-container", {
			slidesPerView: '1',
			spaceBetween: 48,
			navigation: {
				nextEl: ".slider-button-next",
				prevEl: ".slider-button-prev",
			}
		});
	})();
}
// Objects slider
var objectsSlider = document.querySelectorAll('.highlighted-object-slider .swiper-container');
if (objectsSlider.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var objSlider = new Swiper(".highlighted-object-slider .swiper-container", {
			slidesPerView: '1',
			navigation: {
				nextEl: ".obj-button-next",
				prevEl: ".obj-button-prev",
			}
		});
	})();
}

// Jump to submitted form
document.querySelectorAll('.react-form').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	if (form) {

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});

		var anchor = el.querySelector('.form').getAttribute('id');
		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);
	}
});

document.querySelectorAll('.contact-form').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	if (form) {

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});

		var anchor = el.querySelector('.form').getAttribute('id');
		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);
	}
});

// file uploads
var uploadInputs = document.querySelectorAll('.fileupload input');
Array.prototype.forEach.call(uploadInputs, function (input) {
	var label = input.nextElementSibling,
		labelVal = label.innerHTML;

	input.addEventListener('change', function (e) {
		var fileName = '';
		if (this.files && this.files.length > 1)
			fileName = (this.getAttribute('data-multiple-caption') || '').replace('{0}', this.files.length);
		else
			fileName = e.target.value.split('\\').pop();

		if (fileName)
			label.setAttribute('data-caption', fileName);
		else
			label.setAttribute('data-caption', labelVal);
	});
});

// Dropdown
const dropdowns = document.querySelectorAll('.dropdown-checkbox');
if (dropdowns.length) {
	var activeDropdown = null;
	var activeOption = null;

	var onDropdownChange = function (el) {
		if (activeDropdown !== null) {
			activeDropdown.checked = false;
		}
		if (el === activeDropdown) {
			activeDropdown = null;
		}
		else {
			activeDropdown = el;
		}
	};

	var updateLabel = function (option, label, associatedDropdown) {
		if (activeOption === option) {
			setTimeout(function () { option.checked = false; }, 50);
			activeOption = null;
			label.innerHTML = label.dataset.placeholder;
			associatedDropdown.classList.remove('has-value');
		}
		else {
			activeOption = option;
			label.innerText = option.value;
			associatedDropdown.classList.add('has-value');
		}
	};

	dropdowns.forEach(el => {
		el.addEventListener("change", async function (e) {
			if (e.type && e.type === 'checkbox') {
				onDropdownChange(el);
			}
		});

		if (el.classList.contains('update-label')) {
			var associatedDropdown = el.nextElementSibling;
			var label = associatedDropdown.querySelector('dt label');
			var options = associatedDropdown.querySelectorAll('dd input');

			options.forEach(option => {
				option.addEventListener('click', function (e) {
					updateLabel(option, label, associatedDropdown);
				});
			});
		}
	});

	document.addEventListener('click', e => {
		if (e.target.closest('.dropdown-checkbox') === null && e.target.closest('.dropdown') === null) {
			if (activeDropdown !== null) {
				onDropdownChange(activeDropdown);
			}
		}
	});
}

//Filter
const objectFilter = document.querySelector('.object-filter');
const overview = document.querySelector('.objects');
const objects = document.querySelector('.objects-overview');
if (objectFilter && overview) {

	var activeFilterDropdown = null;

	var onFilterChange = function (parent, label, siblings) {
		var selectedFilterNames = "";
		siblings.forEach(sibling => {
			if (sibling.checked === true) {
				if (selectedFilterNames === "") {
					selectedFilterNames = sibling.nextElementSibling.innerText;
				}
				else {
					selectedFilterNames += ", " + sibling.nextElementSibling.innerText;
				}
			}
		});

		if (selectedFilterNames !== "") {
			label.innerText = selectedFilterNames;
			parent.classList.add('has-values');
		}
		else {
			parent.classList.remove('has-values');
			label.innerText = label.dataset.text;
		}
	};

	var uncheckFilters = function (parent, label, filters) {
		filters.forEach(filter => {
			filter.checked = false;
		});
		onFilterChange(parent, label, filters);
	};

	var filter = function (e) {

		// Get the checked inputs from the form
		const data = new URLSearchParams(new FormData(objectFilter));
		console.log(data);

		// Get new overview
		fetch(window.location.pathname, {
			method: "post",
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			}
		})
			.then(response => response.text())
			.then(data => {

				// Empty overview
				overview.innerHTML = '';

				// Create DOM
				var parser = new DOMParser();
				var doc = parser.parseFromString(data, 'text/html');
				var hasItems = false;

				// Add items with animation
				doc.querySelectorAll('.column').forEach(function (child, i) {
					child.classList.add("is-hidden");
					overview.appendChild(child);
					setTimeout(function () { child.classList.remove('is-hidden'); }, 40 * i);
					i++;

					if (!child.classList.contains('is-100')) {
						hasItems = true;
					}
				});

				// Remove old navigation
				var oldprojectsContainer = document.querySelector('.overview-navigation');
				if (oldprojectsContainer) {
					oldprojectsContainer.remove();
				}

				// Add new navigation
				var paginationContainer = doc.querySelector('.overview-navigation');
				if (paginationContainer) {
					objects.appendChild(paginationContainer);
				}

				// Make items the same height
				if (hasItems) {
					//new ResponsiveAutoHeight('.same-height');
				}
			});

		// Update the url
		if (history.pushState) {

			if (data.toString() === "") {
				window.history.replaceState({}, '', `${location.pathname}`);
			}
			else {
				window.history.replaceState({}, '', `${location.pathname}?${data}`);
			}
		}
	};

	var objectFilterDropDowns = objectFilter.querySelectorAll('.dropdown-checkbox');
	if (objectFilterDropDowns) {
		objectFilterDropDowns.forEach(el => {
			if (el.tagName.toLowerCase() === "input") {
				// Change label with selected filter names
				var associatedDropdown = el.nextElementSibling;
				var filters = associatedDropdown.querySelectorAll('.filter__input');
				var label = associatedDropdown.querySelector('dt label');
				filters.forEach(filter => {
					filter.addEventListener("change", async function (e) {
						onFilterChange(el, label, filters);
					});
				});
				// Clear selected filters 
				var button = associatedDropdown.querySelector('dt button');
				button.addEventListener("click", async function (e) {
					//console.log("dt button click");
					uncheckFilters(el, label, filters);
					filter(e);
					onDropdownChange(activeFilterDropdown);
					e.preventDefault();
				});
			}
		});
	}

	// Get all the inputs
	var inputs = objectFilter.querySelectorAll('input.filter__input');
	inputs.forEach(el => {

		// Add on change event to inputs
		el.addEventListener("change", async function (e) {
			filter(e);
			e.preventDefault();
		});
	});
}

var comparison = document.getElementById("comparison");
if (comparison) {
	var comparisonLink = document.getElementById("comparisonlink");
	if (comparisonLink) {
		comparisonLink.classList.remove("hidden");
	}
}

var navigationLinks = document.getElementsByClassName("topnavdropdown");
var navigationIterator = 0;
if (navigationLinks) {
	navigationLinks.forEach(navlink => {
		if (navigationIterator >= 1) {
			navlink.classList.add("nav-hidden");
		}
		navigationIterator++;
	});
}

var mobileTopNav = document.getElementById("topnavdropdown-0");
var mobileTopNavStatus = 0;
if (mobileTopNav) {
	mobileTopNav.addEventListener('click', function (e) {
		if (mobileTopNavStatus == 0) {
			navigationLinks.forEach(navlink => {
				if (navlink != navigationLinks[0]) {
					navlink.classList.remove("nav-hidden");
				}
			});
			mobileTopNav.classList.add("hide-toggle");
			mobileTopNavStatus++;
		}
		else if (mobileTopNavStatus == 1) {
			navigationLinks.forEach(navlink => {
				if (navlink != navigationLinks[0]) {
					navlink.classList.add("nav-hidden");
				}
			});
			mobileTopNav.classList.remove("hide-toggle");
			mobileTopNavStatus--;
		}
	});
}